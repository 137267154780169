<template>
  <div class="home">
    <div class="homeSec01Float">
      <div class="homeSec01FloatInner homeSec01FloatInner01">
        <div class="homeSec01Img trans"></div>
        <div class="homeSec01Ewm trans">
          <div class="homeSec01EwmW">
            <img src="../assets/img/ewm_zt.jpg" alt="" />
            <p style="height: 30px; line-height: 30px">云游六建</p>
          </div>
        </div>
      </div>
      <div class="homeSec01FloatInner homeSec01FloatInner03">
        <div class="homeSec01Img trans"></div>
        <div class="homeSec01Ewm trans">
          <div class="homeSec01EwmW">
            <img src="../assets/img/ewm_gzh.jpg" alt="" />
            <p style="height: 30px; line-height: 30px">企业微信</p>
          </div>
        </div>
      </div>
      <div class="homeSec01FloatInner homeSec01FloatInner02">
        <div class="homeSec01Img trans"></div>
        <div class="homeSec01Ewm trans">
          <div class="homeSec01EwmW">
            <img src="../assets/img/ewm_dy.png" alt="" />
            <p style="height: 30px; line-height: 30px">官方抖音</p>
          </div>
        </div>
      </div>
      <div class="homeSec01FloatInner homeSec01FloatInner04">
        <div class="homeSec01Img trans"></div>
        <div class="homeSec01Ewm trans">
          <div class="homeSec01EwmW">
            <img src="../assets/img/ewm_zz.jpg" alt="" />
            <p style="height: 30px; line-height: 30px">企业杂志</p>
          </div>
        </div>
      </div>
      <div class="homeSec01FloatInner homeSec01FloatInner05">
        <div class="homeSec01Img trans"></div>
        <div class="homeSec01Ewm trans">
          <div class="homeSec01EwmW">
            <a target="_blank" href="https://oa.gsljw.com/redirect/">
              <img src="../assets/img/ewm_oa2.png" alt="" style="width: 100%" />
              <p
                style="
                  height: 30px;
                  line-height: 30px;
                  margin-top: 4px;
                  font-weight: bold;
                  font-size: 14px;
                  color: #2c3e50;
                "
              >
                OA办公
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="homeSec01">
      <!-- <div class="wrap"> -->
      <div
        class="homeSec01Box homeBanner wow bounceInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.5s"
      >
        <div class="homeSec01Wrap">
          <!-- <img src="../assets/img/img_banner.png" alt=""> -->
          <!-- <img src="../assets/video/video_banner_01.jpg" alt=""> -->
          <div class="homeSec01Video" style="height: 100vh; width: 100vw">
            <!-- <video id="videoBanner" class="video-js vjs-default-skin" preload="auto" style="width: 100%; height: 100%;">
              <source :src="videoUrl" type="video/mp4">
            </video> -->
            <video
              loop
              muted
              autoplay
              style="height: 100%; width: 100%; object-fit: fill"
            >
              <source
                src="../assets/video/网站大屏20240102.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
      <!-- <el-button @click="handleClose">暂停</el-button>
      <el-button @click="handlePlay">播放</el-button> -->
      <!-- </div> -->
    </div>

    <div class="homeSec01Small">
      <div
        class="homeSec01BoxSmall homeBanner wow bounceInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.5s"
      >
        <div class="homeSec01WrapSmall">
          <div class="swiper-container" id="bigPhoto">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in sec02XwsdSmallList"
                :key="index"
              >
                <img :src="item.img" alt="" style="width: 100%; height: 100%" />
              </div>
            </div>
            <!-- <div class="swiper-button-prev swiper-button-prev-banner" ></div>
            <div class="swiper-button-next swiper-button-next-banner" ></div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="homeContainer">
      <div class="homeContainerBg homeContainerBg01"></div>
      <div class="homeContainerBg homeContainerBg02"></div>
      <div class="homeSec02">
        <div class="wrap">
          <div class="homeSec02Box">
            <div class="homeSec02Wrap homeSec02Wrap02 homeSec02Xwsd">
              <el-row :gutter="45" style="margin: 0">
                <el-col
                  :lg="16"
                  :xs="24"
                  :xl="16"
                  :md="24"
                  :sm="24"
                  style="padding-left: 10px; padding-right: 10px"
                >
                  <!-- <div class="el-row-45">
                <div class="el-col-16"> -->
                  <div
                    class="homeSec02XwsdHd wow bounceInDown"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.2s"
                  >
                    <ul class="homeSec02XwsdHdList clearfloat">
                      <li
                        v-for="(item, index) in xwsdHdList"
                        :key="index"
                        class="trans switchoverBig"
                        @mouseenter="homeSec02XwsdHd(index)"
                      >
                        <a
                          target="_blank"
                          :href="item.url"
                          :class="{ active: activeXwsdHd == index }"
                        >
                          {{ item.title }}
                        </a>
                      </li>
                      <li
                        v-for="(item, index) in xwsdHdList"
                        :key="index + '001'"
                        class="trans switchoverSmall"
                        @click="homeSec02XwsdHd(index)"
                      >
                        <a
                          target="_blank"
                          :class="{ active: activeXwsdHd == index }"
                        >
                          {{ item.title }}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <el-row
                    :gutter="30"
                    class="wow bounceInUp"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.6s"
                  >
                    <el-col :lg="13" :xs="24" :md="13" :xl="13" :sm="13">
                      <!-- <div  class="el-row-30 wow bounceInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                    <div class="el-col-13"> -->
                      <el-carousel class="carousel" indicator-position="none">
                        <el-carousel-item
                          v-for="(item, index) in sec02XwsdList"
                          :key="index"
                        >
                          <a
                            class="sec02XwsdListWrap"
                            v-if="item.url && item.url !== ''"
                            target="_blank"
                            :href="item.url"
                          >
                            <img :src="item.thumb1" alt="" />
                            <div class="homeSec02XwsdBt">
                              <p class="single-line">{{ item.title }}</p>
                            </div>
                          </a>
                          <a
                            class="sec02XwsdListWrap"
                            v-else
                            target="_blank"
                            :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                          >
                            <img :src="item.thumb1" alt="" />
                            <div class="homeSec02XwsdBt">
                              <p class="single-line">{{ item.title }}</p>
                            </div>
                          </a>
                        </el-carousel-item>
                      </el-carousel>
                      <!-- </div> -->
                    </el-col>
                    <el-col :lg="11" :xs="24" :xl="11" :md="11" :sm="11">
                      <!-- <div class="el-col-11"> -->
                      <ul class="xwsdList" v-if="activeXwsdHd == 0">
                        <li
                          class="clearfloat"
                          v-for="(item, index) in xwsdList01"
                          :key="index"
                        >
                          <div class="fl ico"></div>
                          <div class="fr time">
                            {{ dayjs(item.publishDate).format("YYYY/MM/DD") }}
                          </div>
                          <div class="inner single-line">
                            <a
                              v-if="item.url && item.url !== ''"
                              target="_blank"
                              :href="item.url"
                              >{{ item.title }}</a
                            >
                            <a
                              v-else
                              target="_blank"
                              :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                              >{{ item.title }}</a
                            >
                          </div>
                        </li>
                      </ul>
                      <ul class="xwsdList" v-if="activeXwsdHd == 1">
                        <li
                          class="clearfloat"
                          v-for="(item, index) in xwsdList02"
                          :key="index"
                        >
                          <div class="fl ico"></div>
                          <div class="fr time">
                            {{ dayjs(item.publishDate).format("YYYY/MM/DD") }}
                          </div>
                          <div class="inner single-line">
                            <a
                              v-if="item.url && item.url !== ''"
                              target="_blank"
                              :href="item.url"
                              >{{ item.title }}</a
                            >
                            <a
                              v-else
                              target="_blank"
                              :href="`/detail/01150003/${item.entTArticleUuid}`"
                              >{{ item.title }}</a
                            >
                          </div>
                        </li>
                      </ul>
                      <ul class="xwsdList" v-if="activeXwsdHd == 2">
                        <li
                          class="clearfloat"
                          v-for="(item, index) in xwsdList03"
                          :key="index"
                        >
                          <div class="fl ico"></div>
                          <div class="fr time">
                            {{ dayjs(item.publishDate).format("YYYY/MM/DD") }}
                          </div>
                          <div class="inner single-line">
                            <a
                              v-if="item.url && item.url !== ''"
                              target="_blank"
                              :href="item.url"
                              >{{ item.title }}</a
                            >
                            <a
                              v-else
                              target="_blank"
                              :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                              >{{ item.title }}</a
                            >
                          </div>
                        </li>
                      </ul>
                      <ul class="xwsdList" v-if="activeXwsdHd == 3">
                        <li
                          class="clearfloat"
                          v-for="(item, index) in xwsdList04"
                          :key="index"
                        >
                          <div class="fl ico"></div>
                          <div class="fr time">
                            {{ dayjs(item.publishDate).format("YYYY/MM/DD") }}
                          </div>
                          <div class="inner single-line">
                            <a
                              v-if="item.url && item.url !== ''"
                              target="_blank"
                              :href="item.url"
                              >{{ item.title }}</a
                            >
                            <a
                              v-else
                              target="_blank"
                              :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                              >{{ item.title }}</a
                            >
                          </div>
                        </li>
                      </ul>
                      <!-- </div> -->
                      <!-- </div> -->
                      <!-- </div> -->
                    </el-col>
                  </el-row>
                </el-col>
                <!-- <div class="el-col-8 wow bounceInUp" data-wow-duration="0.8s" data-wow-delay="0.8s"> -->
                <el-col
                  :lg="8"
                  :xs="24"
                  :xl="8"
                  :md="8"
                  :sm="9"
                  class="wow bounceInUp"
                  data-wow-duration="0.8s"
                  data-wow-delay="0.8s"
                  style="padding-left: 10px; padding-right: 10px"
                >
                  <div class="xwsdBrief">
                    <a href="/list/01150004" target="_blank">
                      <div class="xwsdBriefVidoe" v-html="videoBriefCont">
                        <!-- <a href="/list/01150004" target="_blank">
                          <video id="videoBrief" class="video-js vjs-default-skin" preload="auto" style="width: 100%; height: 100%;">
                            <source :src="videoUrlBrief" type="video/mp4">
                          </video>
                        </a> -->
                      </div>
                    </a>
                    <div class="xwsdBriefWrap">
                      <a
                        href="/list/01150004"
                        target="_blank"
                        style="color: #333"
                      >
                        {{ xwsdBrief | ellipsis1 }}
                        <span style="color: #0062b1">【查看更多】</span>
                      </a>
                    </div>
                  </div>
                  <!-- </div> -->
                  <!-- </div> -->
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>

      <div class="medium">
        <el-row :gutter="45" style="margin: 0">
          <!-- 视频 -->
          <el-col
            :lg="8"
            :xs="24"
            :xl="8"
            :sm="12"
            class="wow bounceInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.8s"
            style="padding-left: 10px; padding-right: 10px"
          >
            <div class="xwsdBrief2">
              <a href="/list/01150004" target="_blank">
                <div class="xwsdBriefVidoe" v-html="videoBriefCont">
                  <!-- <a href="/list/01150004" target="_blank">
                    <video id="videoBrief" class="video-js vjs-default-skin" preload="auto" style="width: 100%; height: 100%;">
                      <source :src="videoUrlBrief" type="video/mp4">
                    </video>
                  </a> -->
                </div>
              </a>
              <div class="xwsdBriefWrap">
                <a href="/list/01150004" target="_blank" style="color: #333">
                  {{ xwsdBrief | ellipsis1 }}
                  <span style="color: #0062b1">【查看更多】</span>
                </a>
              </div>
            </div>
          </el-col>

          <!-- 信息公开 -->
          <el-col
            :lg="7"
            :xs="24"
            :xl="7"
            :sm="12"
            style="padding-left: 10px; padding-right: 10px"
            class="infoMedium"
          >
            <div class="homeSecDjRight2">
              <div
                class="homeSecDjRightBg wow bounceInUp"
                data-wow-duration="0.8s"
                data-wow-delay="0.8s"
              ></div>
              <div
                class="homeSecDjRightHd wow bounceInRight"
                data-wow-duration="0.8s"
                data-wow-delay="1s"
              >
                信息公开
              </div>
              <div
                class="homeSecDjRightWrap wow bounceInUp"
                data-wow-duration="0.8s"
                data-wow-delay="1.4s"
              >
                <div class="homeSecDjRightCont">
                  <div class="link-container swiper-container" id="link">
                    <ul class="xwsdList link-wrapper swiper-wrapper">
                      <li
                        class="link-slide swiper-slide clearfloat"
                        v-for="(item, index) in xwsdList05"
                        :key="index + '001'"
                      >
                        <div class="fl ico"></div>
                        <div class="fr time">
                          {{ dayjs(item.publishDate).format("MM-DD") }}
                        </div>
                        <div class="inner single-line">
                          <a
                            v-if="item.url && item.url !== ''"
                            target="_blank"
                            :href="item.url"
                            >{{ item.title }}</a
                          >
                          <a
                            v-else
                            target="_blank"
                            :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                            >{{ item.title }}</a
                          >
                        </div>
                      </li>
                    </ul>
                    <div class="swiper-scrollbar"></div>
                  </div>
                </div>
                <div class="homeSecDjRightMore">
                  <a href="/list/011500050001" target="_blank">查看更多</a>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="homebanner wrap" style="position: relative; z-index: 99;">
        <a :href="bannerurl" target="_blank" >
          <img :src="bannerlist" style="width: 100%" />
        </a>
      </div>

      <div class="homeSecDj">
        <div class="wrap">
          <el-row :gutter="25" style="margin: 0">
            <el-col :lg="17" :xs="24" :xl="17" :md="24" :sm="24">
              <div
                class="homeSecDjLeft wow bounceInUp"
                data-wow-duration="0.8s"
                data-wow-delay="0.4s"
              >
                <div class="bg"></div>
                <div class="homeSecDjLeftCont clearfloat">
                  <div
                    class="fl homeSecDjLeftGrop homeSecDjLeftGrop01 wow fadeInDown"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.6s"
                  >
                    <a href="http://jw.gsljw.com/" target="_blank">
                      <div class="inner innerIco trans discipline">
                        <img src="../assets/img/ico_dj_jj.png" alt="" />
                      </div>
                      <div class="inner innerCont discipline">
                        <div class="tit">纪检举报中心</div>
                        <div class="des">忠诚 严谨 担当</div>
                      </div>
                    </a>
                  </div>
                  <div
                    class="fl homeSecDjLeftGrop homeSecDjLeftGrop012 wow fadeInLeft"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.8s"
                  >
                    <a href="https://ds.gsljw.com/" target="_blank">
                      <div class="inner innerIco trans e-commerce">
                        <img src="../assets/img/ico_dj_cg.png" alt="" />
                      </div>
                      <div class="inner innerCont e-commerce">
                        <div class="tit">电商采购平台</div>
                        <div class="des">智慧 高效 专业</div>
                      </div>
                    </a>
                  </div>
                  <div
                    class="fl homeSecDjLeftGrop homeSecDjLeftGrop013 wow fadeInRight"
                    data-wow-duration="0.8s"
                    data-wow-delay="1s"
                  >
                    <a href="https://zp.gsljw.com/" target="_blank">
                      <div class="inner innerIco trans recruit">
                        <img src="../assets/img/ico_dj_us.png" alt="" />
                      </div>
                      <div class="inner innerCont recruit">
                        <div class="tit">加 入 我 们</div>
                        <div class="des">青春 梦想 价值</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="7" :xs="24" :xl="7" :md="7" :sm="12">
              <div class="homeSecDjRight">
                <div
                  class="homeSecDjRightBg wow bounceInUp"
                  data-wow-duration="0.8s"
                  data-wow-delay="0.8s"
                ></div>
                <div
                  class="homeSecDjRightHd wow bounceInRight"
                  data-wow-duration="0.8s"
                  data-wow-delay="1s"
                >
                  信息公开
                </div>
                <div
                  class="homeSecDjRightWrap wow bounceInUp"
                  data-wow-duration="0.8s"
                  data-wow-delay="1.4s"
                >
                  <div class="homeSecDjRightCont">
                    <div class="link-container swiper-container" id="link2">
                      <ul class="xwsdList link-wrapper swiper-wrapper">
                        <li
                          class="link-slide swiper-slide clearfloat"
                          v-for="(item, index) in xwsdList06"
                          :key="index"
                        >
                          <div class="fl ico"></div>
                          <div class="fr time">
                            {{ dayjs(item.publishDate).format("MM-DD") }}
                          </div>
                          <div class="inner single-line">
                            <a
                              v-if="item.url && item.url !== ''"
                              target="_blank"
                              :href="item.url"
                              >{{ item.title }}</a
                            >
                            <a
                              v-else
                              target="_blank"
                              :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                              >{{ item.title }}</a
                            >
                          </div>
                        </li>
                      </ul>
                      <div class="swiper-scrollbar"></div>
                    </div>
                  </div>
                  <div class="homeSecDjRightMore">
                    <a href="/list/011500050001" target="_blank">查看更多</a>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="homeSec04">
      <div
        class="wow bounceInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.5s"
      >
        <ul
          class="homeSec04List clearfloat"
          :class="{ viewVideo: videoActive !== -1 }"
        >
          <li
            :class="{ active: videoActive == 0 }"
            @mouseenter="homeSec04Enter(0)"
            @mouseleave="homeSec04Leave(0)"
          >
            <a href="/list/011800010001" target="_blank">
              <transition name="el-fade-in-linear">
                <div class="homeSec04Img" v-show="videoActive !== 0">
                  <el-image
                    class="homeSec04Img"
                    style="width: 100%; height: 100%"
                    :src="sec04List[0].img"
                    fit="cover"
                  >
                  </el-image>
                </div>
              </transition>
              <div class="homeSec04Video">
                <video
                  id="videoLj01"
                  class="video-js vjs-default-skin"
                  preload="auto"
                  style="width: 100%; height: 100%"
                >
                  <source :src="sec04List[0].url" type="video/mp4" />
                </video>
              </div>
              <div class="homeSec04Txt">
                {{ sec04List[0].title }}
              </div>
            </a>
          </li>
          <li
            :class="{ active: videoActive == 1 }"
            @mouseenter="homeSec04Enter(1)"
            @mouseleave="homeSec04Leave(1)"
          >
            <transition name="el-fade-in-linear">
              <div class="homeSec04Img" v-show="videoActive !== 1">
                <el-image
                  class="homeSec04Img"
                  style="width: 100%; height: 100%"
                  :src="sec04List[1].img"
                  fit="cover"
                ></el-image>
              </div>
            </transition>
            <div class="homeSec04Video">
              <video
                id="videoLj02"
                class="video-js vjs-default-skin"
                preload="auto"
                style="width: 100%; height: 100%"
              >
                <source :src="sec04List[1].url" type="video/mp4" />
              </video>
            </div>
            <div class="homeSec04Txt">
              {{ sec04List[1].title }}
            </div>
          </li>
          <li
            :class="{ active: videoActive == 2 }"
            @mouseenter="homeSec04Enter(2)"
            @mouseleave="homeSec04Leave(2)"
          >
            <a href="/list/01180002" target="_blank">
              <transition name="el-fade-in-linear">
                <div class="homeSec04Img" v-show="videoActive !== 2">
                  <el-image
                    class="homeSec04Img"
                    style="width: 100%; height: 100%"
                    :src="sec04List[2].img"
                    fit="cover"
                  >
                  </el-image>
                </div>
              </transition>
              <div class="homeSec04Video">
                <video
                  id="videoLj03"
                  class="video-js vjs-default-skin"
                  preload="auto"
                  style="width: 100%; height: 100%"
                >
                  <source :src="sec04List[2].url" type="video/mp4" />
                </video>
              </div>
              <div class="homeSec04Txt">
                {{ sec04List[2].title }}
              </div>
            </a>
          </li>

          <li
            :class="{ active: videoActive == 3 }"
            @mouseenter="homeSec04Enter(3)"
            @mouseleave="homeSec04Leave(3)"
          >
            <transition name="el-fade-in-linear">
              <div class="homeSec04Img" v-show="videoActive !== 3">
                <el-image
                  class="homeSec04Img"
                  style="width: 100%; height: 100%"
                  :src="sec04List[3].img"
                  fit="cover"
                ></el-image>
              </div>
            </transition>
            <div class="homeSec04Video">
              <video
                id="videoLj04"
                class="video-js vjs-default-skin"
                preload="auto"
                style="width: 100%; height: 100%"
              >
                <source :src="sec04List[3].url" type="video/mp4" />
              </video>
            </div>
            <div class="homeSec04Txt">
              {{ sec04List[3].title }}
            </div>
          </li>
          <li
            :class="{ active: videoActive == 4 }"
            @mouseenter="homeSec04Enter(4)"
            @mouseleave="homeSec04Leave(4)"
          >
            <transition name="el-fade-in-linear">
              <div class="homeSec04Img" v-show="videoActive !== 4">
                <el-image
                  class="homeSec04Img"
                  style="width: 100%; height: 100%"
                  :src="sec04List[4].img"
                  fit="cover"
                ></el-image>
              </div>
            </transition>
            <div class="homeSec04Video">
              <video
                id="videoLj05"
                class="video-js vjs-default-skin"
                preload="auto"
                style="width: 100%; height: 100%"
              >
                <source :src="sec04List[4].url" type="video/mp4" />
              </video>
            </div>
            <div class="homeSec04Txt">
              {{ sec04List[4].title }}
            </div>
          </li>
          <li
            :class="{ active: videoActive == 5 }"
            @mouseenter="homeSec04Enter(5)"
            @mouseleave="homeSec04Leave(5)"
          >
            <a href="/list/01200002" target="_blank">
              <transition name="el-fade-in-linear">
                <div class="homeSec04Img" v-show="videoActive !== 5">
                  <el-image
                    class="homeSec04Img"
                    style="width: 100%; height: 100%"
                    :src="sec04List[5].img"
                    fit="cover"
                  ></el-image>
                </div>
              </transition>
              <div class="homeSec04Video">
                <video
                  id="videoLj06"
                  class="video-js vjs-default-skin"
                  preload="auto"
                  style="width: 100%; height: 100%"
                >
                  <source :src="sec04List[5].url" type="video/mp4" />
                </video>
              </div>
              <div class="homeSec04Txt">
                {{ sec04List[5].title }}
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- 手机视频适配 -->
    <div class="homeSec05">
      <div
        class="wow bounceInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.5s"
      >
        <ul
          class="homeSec05List clearfloat"
          :class="{ viewVideo: videoActive !== -1 }"
        >
          <div class="threeDiv1">
            <li
              :class="{ active: videoActive == 0 }"
              @click="homeSec04Click(sec04List[0])"
            >
              <a href="/list/011800010001" target="_blank">
                <transition name="el-fade-in-linear">
                  <div class="homeSec05Img" v-show="videoActive !== 0">
                    <el-image
                      class="homeSec05Img"
                      style="width: 100%; height: 100%"
                      :src="sec04List[0].img"
                      fit="cover"
                    >
                    </el-image>
                  </div>
                </transition>
                <!-- <div class="homeSec05Video">
                  <video id="videoLj01" class="video-js vjs-default-skin" preload="auto" style="width: 100%; height: 100%;">
                    <source :src="sec04List[0].url" type="video/mp4">
                  </video>
                </div> -->
                <div class="homeSec05Txt">
                  {{ sec04List[0].title }}
                </div>
              </a>
            </li>
            <li
              :class="{ active: videoActive == 1 }"
              @click="homeSec04Click(sec04List[1])"
            >
              <transition name="el-fade-in-linear">
                <div class="homeSec05Img" v-show="videoActive !== 1">
                  <el-image
                    class="homeSec05Img"
                    style="width: 100%; height: 100%"
                    :src="sec04List[1].img"
                    fit="cover"
                  ></el-image>
                </div>
              </transition>
              <!-- <div class="homeSec05Video">
                <video id="videoLj02" class="video-js vjs-default-skin" preload="auto" style="width: 100%; height: 100%;">
                  <source :src="sec04List[1].url" type="video/mp4">
                </video>
              </div> -->
              <div class="homeSec05Txt">
                {{ sec04List[1].title }}
              </div>
            </li>
            <li
              :class="{ active: videoActive == 2 }"
              @click="homeSec04Click(sec04List[2])"
            >
              <a href="/list/01180002" target="_blank">
                <transition name="el-fade-in-linear">
                  <div class="homeSec05Img" v-show="videoActive !== 2">
                    <el-image
                      class="homeSec05Img"
                      style="width: 100%; height: 100%"
                      :src="sec04List[2].img"
                      fit="cover"
                    >
                    </el-image>
                  </div>
                </transition>
                <!-- <div class="homeSec05Video">
                  <video id="videoLj03" class="video-js vjs-default-skin" preload="auto" style="width: 100%; height: 100%;">
                    <source :src="sec04List[2].url" type="video/mp4">
                  </video>
                </div> -->
                <div class="homeSec05Txt">
                  {{ sec04List[2].title }}
                </div>
              </a>
            </li>
          </div>
          <div class="threeDiv2">
            <li
              :class="{ active: videoActive == 3 }"
              @click="homeSec04Click(sec04List[3])"
            >
              <transition name="el-fade-in-linear">
                <div class="homeSec05Img" v-show="videoActive !== 3">
                  <el-image
                    class="homeSec05Img"
                    style="width: 100%; height: 100%"
                    :src="sec04List[3].img"
                    fit="cover"
                  >
                  </el-image>
                </div>
              </transition>
              <!-- <div class="homeSec05Video">
                <video id="videoLj04" class="video-js vjs-default-skin" preload="auto" style="width: 100%; height: 100%;">
                  <source :src="sec04List[3].url" type="video/mp4">
                </video>
              </div> -->
              <div class="homeSec05Txt">
                {{ sec04List[3].title }}
              </div>
            </li>
            <li
              :class="{ active: videoActive == 4 }"
              @click="homeSec04Click(sec04List[4])"
            >
              <transition name="el-fade-in-linear">
                <div class="homeSec05Img" v-show="videoActive !== 4">
                  <el-image
                    class="homeSec05Img"
                    style="width: 100%; height: 100%"
                    :src="sec04List[4].img"
                    fit="cover"
                  >
                  </el-image>
                </div>
              </transition>
              <!-- <div class="homeSec05Video">
                <video id="videoLj05" class="video-js vjs-default-skin" preload="auto" style="width: 100%; height: 100%;">
                  <source :src="sec04List[4].url" type="video/mp4">
                </video>
              </div> -->
              <div class="homeSec05Txt">
                {{ sec04List[4].title }}
              </div>
            </li>
            <li
              :class="{ active: videoActive == 5 }"
              @click="homeSec04Click(sec04List[5])"
            >
              <a href="/list/01200002" target="_blank">
                <transition name="el-fade-in-linear">
                  <div class="homeSec05Img" v-show="videoActive !== 5">
                    <el-image
                      class="homeSec05Img"
                      style="width: 100%; height: 100%"
                      :src="sec04List[5].img"
                      fit="cover"
                    >
                    </el-image>
                  </div>
                </transition>
                <!-- <div class="homeSec05Video">
                  <video id="videoLj06" class="video-js vjs-default-skin" preload="auto" style="width: 100%; height: 100%;">
                    <source :src="sec04List[5].url" type="video/mp4">
                  </video>
                </div> -->
                <div class="homeSec05Txt">
                  {{ sec04List[5].title }}
                </div>
              </a>
            </li>
          </div>
        </ul>
      </div>
      <!-- <div>
      <div class="homeSec05Video" style="margin: 10px 0; ">
        <video id="videoLj01" class="video-js vjs-default-skin" preload="auto" style="width: 100%; height: 100%;">
          <source :src="listindex" type="video/mp4">
        </video>
      </div>
      </div> -->
    </div>
    <el-dialog
      title=" "
      top="0px"
      destroy-on-close
      @close="closeDialog"
      :visible.sync="dialogVisible"
      width="auto"
    >
      <div class="dialogContainer">
        <div class="homeSec05Video" style="margin: 10px 0">
          <!-- <video id="videoLj01" class="video-js vjs-default-skin" preload="auto" autoplay="true" style="width: 100%; height: 100%;">
            <source :src="listindexUrl" type="video/mp4">
          </video> -->
          <video
            class="groupVideo"
            controls=""
            preload="none"
            :src="listindexUrl"
            autoplay="autoplay"
            :poster="listindeximg"
            style="width: 100%; height: 100%"
          >
            <source :src="listindexUrl" type="video/mp4" />
          </video>
        </div>
      </div>
      <!-- <div slot="footer" class="dialog-footer">
        <div class="formFooter" style="text-align: center;">
          <el-button @click="homeSec04Close" size="small">取消</el-button>
        </div>
      </div> -->
    </el-dialog>

    <!-- 飘窗 -->
    <window-box ref="windowBox"></window-box>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import videojs from "video.js";
import "videojs-contrib-hls";
import "@videojs/http-streaming";
import Swiper from "swiper";
import windowBox from "@/components/windowBox/index.vue";
import {
  // picArticles,
  picArticlesRecommend,
  articles,
  getArtInfo,
  getPageInfo,
  getAdvert,
} from "@/api/common";
export default {
  components: {
    windowBox,
  },
  name: "home",
  data() {
    return {
      dialogVisible: false,
      listindexUrl: "",
      listindeximg: "",
      listindexTitle: "",
      videoBriefCont: "",
      articleUuidInfo: "",
      playerBanner: null,
      playerBrief: null,
      videoLj01: null,
      videoLj02: null,
      videoLj03: null,
      videoLj04: null,
      videoLj05: null,
      videoLj06: null,
      // videoUrl: 'http://img.gsjtzn.cn/Images/Upload/Video/20220128/6377897129738584384428504.mp4',
      videoUrl: require("../assets/video/20dNew.mp4"),
      // videoUrlBrief: require('../assets/video/video_news.mp4'),
      videoUrlBrief: "",
      videoActive: -1,
      activeHd: 0,
      activeSec02Hd: 1,
      activeXwsdHd: 0,
      activeGszbHd: 0,
      activeDjHd: 0,
      activeDjInner: 0,
      xwsdHdList: [
        {
          title: "媒体聚焦",
          url: "/list/01150007",
        },
        {
          title: "集团要闻",
          url: "/list/01150003",
        },
        {
          title: "公司新闻",
          url: "/list/01150001",
        },
        {
          title: "基层声音",
          url: "/list/01150002",
        },
      ],
      bannerurl: undefined,
      bannerlist: [],
      sec02XwsdList: [],
      xwsdList01: [],
      xwsdList02: [],
      xwsdList03: [],
      xwsdList04: [],
      xwsdList05: [],
      xwsdList06: [],
      videoList: [],
      sec02XwsdSmallList: [
        {
          img: require("../assets/image/20d.jpg"),
        },
        // {
        //   img: require('../assets/image/small.jpg'),
        // },
        // {
        //   img: require('../assets/image/small2.jpg'),
        // },
        // {
        //   img: require('../assets/image/small3.jpg'),
        // },
      ],
      sec04List: [
        {
          title: "精品六建",
          img: require("../assets/video/video_jplj_01.jpg"),
          url: require("../assets/video/video_jplj.mp4"),
        },
        {
          title: "绿色六建",
          img: require("../assets/video/video_lslj_01.jpg"),
          url: require("../assets/video/video_lslj.mp4"),
        },
        {
          title: "科技六建",
          img: require("../assets/video/video_kjlj_01.jpg"),
          url: require("../assets/video/video_kjlj.mp4"),
        },
        {
          title: "创新六建",
          img: require("../assets/video/video_cxlj_01.jpg"),
          url: require("../assets/video/video_cxlj.mp4"),
        },
        {
          title: "智慧六建",
          img: require("../assets/video/video_zhlj_01.jpg"),
          url: require("../assets/video/video_zhlj.mp4"),
        },
        {
          title: "幸福六建",
          img: require("../assets/video/video_xflj_01.jpg"),
          url: require("../assets/video/video_xflj.mp4"),
        },
      ],
      xwsdBrief: "",
      // xwsdBrief: '光耀陇原，精工筑就广厦万间。肩负时代建设伟业，甘肃六建从1952年诞生之日起，开拓进取、步履坚定；敢为人先、从容豪迈。甘肃第六建设集团股份有限公司（以下简称甘肃六建）隶属于中国企业500强甘肃建投，是全国建筑业先进企业，全国实施用户满意工程标杆企业，全国建筑业AAA级信用企业，先后3次问鼎中国建设工程最高荣誉鲁班奖，3次荣获国家优质工程奖，荣获百余项国家级荣誉，得到行业顶级认可。'
    };
  },
  mounted() {
    var _this = this;
    // this.$parent.color = `#000`;
    // this.$parent.backgroundColor = "#fff";
    this.$nextTick(() => {
      let wow = new WOW({
        boxClass: "wow", //需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 0, //距离可视区域多少开始执行动画
        mobile: true, //是否在移动设备上执行动画
        live: true, //异步加载的内容是否有效
      });
      wow.init();

      // _this.videoInitAuto('playerBanner', 'videoBanner')
      // _this.videoInitAuto('playerBrief', 'videoBrief')
      _this.videoInit("videoLj01", "videoLj01");
      _this.videoInit("videoLj02", "videoLj02");
      _this.videoInit("videoLj03", "videoLj03");
      _this.videoInit("videoLj04", "videoLj04");
      _this.videoInit("videoLj05", "videoLj05");
      _this.videoInit("videoLj06", "videoLj06");
    });

    this.articles();
    this.getPageInfo();
    this.getPageInfo2();
    this.getPageInfo3();
    this.picArticles();
    this.getArtInfo();
    this.bannerImg();
    this.getbanner();
  },
  beforeDestroy() {
    if (this.playerBanner != null) {
      this.playerBanner.dispose(); // dispose()会直接删除Dom元素
    }
    if (this.videoLj01 != null) {
      this.videoLj01.dispose();
    }
    if (this.videoLj02 != null) {
      this.videoLj02.dispose();
    }
    if (this.videoLj03 != null) {
      this.videoLj03.dispose();
    }
    if (this.videoLj04 != null) {
      this.videoLj04.dispose();
    }
    if (this.videoLj05 != null) {
      this.videoLj05.dispose();
    }
    if (this.videoLj06 != null) {
      this.videoLj06.dispose();
    }
  },
  filters: {
    //超过100位显示 ...
    ellipsis: function (value) {
      if (!value) return "";
      if (value.length > 54) {
        return value.slice(0, 54) + "...";
      }
      return value;
    },
    //超过100位显示 ...
    ellipsis1: function (value) {
      if (!value) return "";
      if (value.length > 90) {
        return value.slice(0, 90) + "...";
      }
      return value;
    },
    ellipsis3: function (value) {
      if (!value) return "";
      if (value.length > 18) {
        return value.slice(0, 18) + "...";
      }
      return value;
    },
  },
  methods: {
    handleClose() {
      var _this = this;

      _this.playerBanner.pause();
    },
    handlePlay() {
      var _this = this;

      _this.playerBanner.play();
    },
    handleDj(index) {
      var _this = this;

      _this.activeDjInner = index;
    },
    homeSec02Hd(item, index) {
      var _this = this;

      if (item.url && item.url !== "") {
        window.open(item.url);
      } else {
        _this.activeSec02Hd = index;
      }
    },
    homeSec02XwsdHd(index) {
      var _this = this;

      _this.activeXwsdHd = index;
    },
    homeSecGszbHd(index) {
      var _this = this;

      _this.activeGszbHd = index;
    },
    homeDjHd(index) {
      var _this = this;

      _this.activeDjHd = index;
    },
    homeSec04Enter(index) {
      var _this = this;
      _this.videoActive = index;
      setTimeout(() => {
        _this["videoLj0" + (index + 1)].play();
      }, 300);
    },
    homeSec04Leave(index) {
      var _this = this;
      _this.videoActive = -1;
      setTimeout(() => {
        _this["videoLj0" + (index + 1)].pause();
        _this["videoLj0" + (index + 1)].currentTime(0); // 播放进度返回到0
      }, 300);
    },
    homeSec04Click(item) {
      var _this = this;
      _this.listindexUrl = item.url;
      _this.listindexTitle = item.title;
      _this.listindeximg = item.img;
      console.log(
        1,
        _this.listindexUrl,
        _this.listindexTitle,
        _this.listindeximg
      );
      this.dialogVisible = true;
    },
    // homeSec04Close() {
    //   var _this = this
    //   this.dialogVisible = false
    //   _this.listindexUrl = ''
    // _this.videoLj01.pause()
    // },
    closeDialog() {
      var _this = this;
      _this.listindexUrl = "";
      _this.listindeximg = "";
      this.dialogVisible = false;
    },
    videoInitAuto(name, id) {
      var _this = this;
      _this[name] = videojs(id, {
        bigPlayButton: false,
        textTrackDisplay: false,
        posterImage: true,
        errorDisplay: false,
        controlBar: true,
        muted: true, //静音模式 、、 解决首次页面加载播放。
        loop: true,
        fluid: true,
        controls: false,
        autoplay: true,
      });
    },
    videoInit(name, id) {
      var _this = this;
      _this[name] = videojs(id, {
        bigPlayButton: false,
        textTrackDisplay: false,
        posterImage: true,
        errorDisplay: false,
        controlBar: true,
        muted: true, //静音模式 、、 解决首次页面加载播放。
        loop: true,
        fluid: true,
        controls: false,
        // autoplay: true,
      });
    },
    articles() {
      var _this = this;

      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: "01150007",
        pageNum: 1,
        pageSize: 8,
      };
      articles(data01).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          _this.xwsdList01 = data.rows;
        }
      });

      let data02 = {
        entTApplicationUuid: _this.webIdGroup,
        channelUuid: "00190001",
        pageNum: 1,
        pageSize: 8,
      };
      articles(data02).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          _this.xwsdList02 = data.rows;
        }
      });

      let data03 = {
        entTApplicationUuid: _this.webId,
        channelUuid: "01150001",
        pageNum: 1,
        pageSize: 8,
      };
      articles(data03).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          _this.xwsdList03 = data.rows;
        }
      });

      let data04 = {
        entTApplicationUuid: _this.webId,
        channelUuid: "01150002",
        pageNum: 1,
        pageSize: 8,
      };
      articles(data04).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          _this.xwsdList04 = data.rows;
        }
      });
    },
    getPageInfo() {
      var _this = this;

      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: "01150005",
        pageNum: 1,
        pageSize: 40,
      };
      getPageInfo(data01).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          _this.xwsdList05 = data.rows;
          this.$nextTick(() => {
            new Swiper("#link", {
              direction: "vertical",
              autoHeight: true,
              speed: 2000,
              autoplay: 500,
              loop: true,
              // grabCursor: true, //手型
              slidesPerView: 6,
              spaceBetween: 0,
              observer: true,
              observeParents: true,
              observeSlideChildren: true,
            });

            // mySwiper.container[0].onmouseover = function() {
            //   mySwiper.stopAutoplay()
            // }
            // mySwiper.container[0].onmouseout = function() {
            //   mySwiper.startAutoplay()
            // }
          });
        }
      });
    },

    getPageInfo3() {
      var _this = this;

      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: "01150005",
        pageNum: 1,
        pageSize: 40,
      };
      getPageInfo(data01).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          _this.xwsdList06 = data.rows;
          this.$nextTick(() => {
            new Swiper("#link2", {
              direction: "vertical",
              autoHeight: true,
              speed: 2000,
              autoplay: 500,
              loop: true,
              grabCursor: true, //手型
              slidesPerView: 6,
              spaceBetween: 0,
              observer: true,
              observeParents: true,
              observeSlideChildren: true,
            });

            // mySwiper.container[0].onmouseover = function() {
            //   mySwiper.stopAutoplay()
            // }
            // mySwiper.container[0].onmouseout = function() {
            //   mySwiper.startAutoplay()
            // }
          });
        }
      });
    },

    getPageInfo2() {
      var _this = this;
      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: "01150004",
        pageNum: 1,
        pageSize: 1000,
      };
      articles(data01).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          // res.data.rows.forEach(line => {
          //   if(!line.url){
          //     _this.articleUuid = line.entTArticleUuid
          //     console.log(123,_this.articleUuid)
          //   }
          // })
          for (var i = 0; i <= res.data.rows.length; i++) {
            if (!res.data.rows[i].url) {
              _this.articleUuidInfo = res.data.rows[i].entTArticleUuid;
              _this.xwsdBrief = res.data.rows[i].artDesc;
              // _this.xwsdBrief = res.data.rows[i].title
              // console.log(123,_this.articleUuidInfo,_this.xwsdBrief)
              break;
            }
          }
          this.$nextTick(() => {
            let data = {
              entTApplicationUuid: _this.webId,
              entTArticleUuid: _this.articleUuidInfo,
            };
            getArtInfo(data).then((res) => {
              const { code, data } = res;
              if (code === 1 && data) {
                _this.videoBriefCont = data.content;
                // _this.newsitem = data
                // data.content = data.content.split('src="')[1].split('"')[0]
                // _this.videoUrlBrief = data.content
                // _this.playerBrief.play()
                // console.log(12456,_this.videoUrlBrief)
              }
            });
          });
        }
      });
    },

    getArtInfo() {
      var _this = this;
      let data = {
        entTApplicationUuid: _this.webId,
        entTArticleUuid: _this.articleUuidInfo,
      };
      getArtInfo(data).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          // _this.videoUrlBrief = data.content
          // data.content = data.content.split('src="')[1].split('"')[0]
          // console.log(456,data.content)
        }
      });
    },

    picArticles() {
      var _this = this;

      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: "01150001",
        pageNum: 1,
        pageSize: 6,
      };
      picArticlesRecommend(data01).then((res) => {
        const { code, data } = res;
        if (code === 1 && data) {
          _this.sec02XwsdList = data.rows;
        }
      });
    },
    bannerImg() {
      new Swiper("#bigPhoto", {
        loop: true,
        effect: "fade",
        // autoplay: true,
        autoplay: 3000,
        speed: 300,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        autoplayDisableOnInteraction: false,
        prevButton: ".swiper-button-prev-banner",
        nextButton: ".swiper-button-next-banner",
      });
    },
    // banner图
    getbanner(){
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        advertType: 1
      }
      getAdvert(data).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length && (_this.bannerlist = data[0].fileName)
          _this.bannerurl = data[0].url
        }
      })
    },
  },
};
</script>
<style>
/* img{ 
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%); 
        filter: grayscale(100%);
        filter: url(/img/2013people/gray.svg#grayscale);
        filter: gray;-webkit-filter: grayscale(1);
    }
    html {filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1); filter: gray;-webkit-filter: grayscale(1);}
    .header .zt_banner img.xjp,.header .zt_banner img.database,.cpc_tit img{display:none;}
    .datalh{display:block;}
    .header{background:#666;} */
.medium {
  display: none;
}
.homeSec05 {
  display: none;
}
.switchoverSmall {
  display: none;
}

.carousel {
  height: 300px;
}
.video-js {
  height: 100% !important;
}
.video-js .vjs-tech {
  object-fit: cover !important;
}
.dark_cover {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.xwsdBriefVidoe iframe {
  width: 100% !important;
  height: 272px;
}
.xwsdBriefVidoe video {
  width: 100% !important;
  height: 272px;
}
.homeSec01 {
  margin-top: 73px;
}

.el-dialog__body {
  padding: 10px 10px 10px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
.el-dialog__footer {
  padding: 0px 20px 10px;
  text-align: right;
  box-sizing: border-box;
}
.homeSec01Small {
  display: none;
}

@media (max-width: 1300px) {
  .wrap {
    width: auto;
  }
  .homeSec01 {
    height: 600px;
    margin-top: auto;
  }
  .homeContainer {
    padding: 30px 0 50px;
    position: relative;
  }
  .nav_down {
    position: absolute;
    width: 100%;
    background-color: rgba(0, 98, 177, 0.4);
    color: #fff;
    left: 0;
    top: 106px;
    text-align: center;
  }
  .homeSec01Video {
    width: 100vw;
    height: 100vh;
  }
}
@media (max-width: 1200px) {
  .wrap {
    width: auto;
  }
  .medium {
    display: block;
    margin-top: 20px;
  }
  .homeSecDjRight {
    display: none;
  }
  .xwsdBrief {
    display: none;
  }
  .homeSecDjRightBg {
    display: none;
  }
  .homeSecDjRightHd::after {
    display: none;
  }
  .homeSecDjRightHd {
    float: right;
    margin-top: -55px;
    left: 0;
  }
  .homeContainer {
    padding: 50px 0 50px;
    position: relative;
  }
  .homeSec01Video {
    width: 100vw;
    height: 100vh;
  }
}
@media (max-width: 992px) {
  /* .homeSec01{
    height: 480px;
    margin-top: auto;
  } */
  .homeSec01 {
    display: none;
  }
  .homeSec01Small {
    display: block;
    height: auto;
  }
  .wrap {
    width: auto;
  }
  .medium {
    display: block;
    margin-top: 20px;
  }
  .homeSecDjRight {
    display: none;
  }
  .xwsdBrief {
    display: none;
  }
  .homeSecDjRightBg {
    display: none;
  }
  .homeSecDjRightHd::after {
    display: none;
  }
  .homeSecDjRightHd {
    float: right;
    margin-top: -50px;
    left: 0;
  }
  .homeContainer {
    padding: 50px 0 50px;
    position: relative;
  }
  .homeSec01Video {
    width: 100vw;
    height: 100vh;
  }
  .switchoverSmall {
    display: block;
  }
  .switchoverBig {
    display: none;
  }
}
@media (max-width: 768px) {
  .medium {
    display: block;
    margin-top: 30px;
  }
  .infoMedium {
    padding-top: 20px;
  }
  .homeSec01 {
    display: none;
  }
  .homeSec01Small {
    display: block;
    height: auto;
  }
  .homeSec01Video {
    width: auto;
  }
  .homeSec02 {
    padding: 0px;
  }
  .homeContainer {
    padding: 10px 0 10px;
    position: relative;
  }
  .homeSec02Xwsd {
    margin-top: 20px;
  }
  .xwsdBrief {
    margin-top: 20px;
  }
  .homeSec02XwsdHd {
    padding: 0 20px;
    margin-bottom: 20px;
  }
  .carousel {
    height: 240px;
  }
  .el-carousel__item,
  .el-carousel__mask {
    height: 240px;
    position: absolute;
    width: 100%;
  }
  .el-carousel__container {
    position: relative;
    height: 240px;
  }
  .xwsdList {
    margin-top: 20px;
  }
  .homeSecDj {
    padding: 35px 0;
  }
  .homeSecDjLeft {
    height: 400px;
  }

  .homeSecDjLeftCont {
    bottom: 21px;
  }
  .homeSecDjLeftGrop .innerIco {
    width: 55px;
    /* line-height: 64px; */
    display: block;
    margin-top: 6px;
  }
  /* .homeSecDjLeftGrop .innerIco:before {
    content: '';
    vertical-align: middle;
    display: inline-block;

} */
  /* .discipline{
  width: 55px;
    line-height: 64px;
    display: block;
}
.discipline::before{
    content: '';
    vertical-align: middle;
    display: inline-block;
} */

  .e-commerce {
    float: left;
    /* margin-left: 4px !important; */
  }
  .recruit {
    float: left;
    /* margin-right: 4px !important; */
  }
  .discipline {
    /* margin: 0 auto; */
    float: left;
  }
  .homeSecDjLeftGrop .inner {
    display: block;
  }
  .homeSecDjLeftGrop {
    margin-top: -30px;
  }
  .homeSecDjRightWrap {
    height: 310px;
    border: 2px solid rgba(23, 113, 190, 0.52);
    box-shadow: 0px 4px 16px 0px rgb(0 0 0 / 16%);
    margin-top: 18px 2px 0 2px;
    margin: 18px 0px 0;
  }
  .homeSecDjRightHd {
    float: right;
    margin-top: -45px;
    /* top: -45px; */
    left: 0;
    display: block;
  }
  .homeSec01Video {
    width: 100vw;
    height: 100vh;
  }
  /* .homeSec01Img {
    width: 44px;
    height: 44px;
    background-size: 27px;
} */
  .homeSec01Img {
    width: 32px;
    height: 32px;
    background-size: 20px;
  }
  .homeSec01Float {
    width: 50px;
    position: fixed;
    left: 2px;
    top: 60%;
    z-index: 66;
  }
  .homeSec05Txt {
    height: 42px;
    line-height: 40px;
  }
  .homeSec01FloatInner {
    height: 38px;
    line-height: 38px;
  }
  .homeSec01FloatInner {
    margin: 6px 0;
  }
  .homeSec01Float a img {
    width: 100%;
  }
  .homeSec01FloatInner a img {
    width: 100%;
    vertical-align: middle;
  }
  .homeSec01Ewm a p {
    margin-top: 4px;
    font-weight: bold;
  }
  .homeSec01Ewm {
    position: absolute;
    width: 100px;
    left: 36px;
    top: -50px;
  }
  .homeSecDjLeftGrop {
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
  }
  .switchoverSmall {
    display: block;
  }
  .switchoverBig {
    display: none;
  }
  .homeSec05List {
    height: auto;
  }

  .homeSec05 {
    padding: 0px 0 40px;
  }

  .homeSec05List {
    height: auto;
    overflow: hidden;
  }

  .homeSec05List .threeDiv1 {
    width: 100%;
    height: 240px;
  }
  .homeSec05List .threeDiv2 {
    width: 100%;
    height: 240px;
  }
  .homeSec05List .threeDiv1 li {
    float: left;
    width: 33.333%;
    height: 240px;
    margin-left: 0;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    -moz-transition: all 0.4s ease-in;
    -webkit-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
  }
  .homeSec05List .threeDiv2 li {
    float: left;
    width: 33.333%;
    height: 240px;
    margin-left: 0;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    -moz-transition: all 0.4s ease-in;
    -webkit-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
  }
  .homeSec05Img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 11;
    left: 0;
    top: 0;
  }
  /* .homeSec05Video {
    position: absolute;
    width: 100%;
    height: 240;
} */
  .homeSec05Txt {
    position: absolute;
    width: 100%;
    height: 42px;
    line-height: 40px;
    padding-top: 30px;
    left: 0;
    bottom: 0;
    text-align: center;
    color: #f3f3f3;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    z-index: 33;
  }
  /* .homeSec05List.viewVideo .threeDiv1 li.active {
    width: 60%;
}
.homeSec05List.viewVideo .threeDiv1 li {
   width: 20%;
}
.homeSec05List.viewVideo .threeDiv2 li.active {
    width: 60%;
}
.homeSec05List.viewVideo .threeDiv2 li {
   width: 20%;
} */
  .footerBox {
    padding: 0px 0 10px;
  }
  .homeSecDjLeftGrop .innerCont {
    margin-left: 25px;
    width: 132px;
  }
  .homeSecDjLeftGrop .innerCont .tit {
    float: left;
  }
  .homeSecDjLeftGrop .innerCont .des {
    float: left;
  }
  .homeSec04 {
    display: none;
  }
  .homeSec05 {
    display: block;
  }
  .el-dialog__wrapper {
    position: fixed;
    top: 24%;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
  }
  .el-dialog__headerbtn {
    position: absolute;
    top: 10px;
    right: 20px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }
  .el-dialog__title {
    line-height: 24px;
    font-size: 16px;
    color: #fff;
  }
  .el-dialog__header {
    padding: 10px 20px 10px;
    /* background-color: #0062b1; */
  }
  /* .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
} */
  .homeSecDjRightCont {
    padding: 15px 6px 0;
    height: 246px;
  }
  .xwsdBrief2 {
    margin-bottom: 30px;
  }
  .homeSecDjRightHd {
    width: 120px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }
}
@media (width: 768px) {
  .homeSecDjLeft {
    height: 450px;
  }
  .homeSecDjLeftGrop {
    margin-top: 0px;
  }
  .switchoverSmall {
    display: block;
  }
  .switchoverBig {
    display: none;
  }
  .carousel {
    height: 240px;
    margin-top: 44px;
  }
  .homeSecDjRight2 {
    margin-top: 54px;
  }
  .homeSec01Img {
    width: 44px;
    height: 44px;
    background-size: 27px;
  }

  .homeSec01Float {
    width: 50px;
    position: fixed;
    left: 12px;
    top: 35%;
    z-index: 66;
  }
  .homeSec05List .threeDiv2 li {
    height: 360px;
  }
  .homeSec05List .threeDiv1 li {
    height: 360px;
  }

  .homeSec01FloatInner {
    margin: 24px 0;
  }
  .homeSecDjRightHd {
    width: 120px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }
  .infoMedium {
    padding-top: 0px;
  }
}
</style>