<template>
  <div>
    <div id="adbox" ref="adbox" v-show="showAdbox" @mouseenter="enterbox" @mouseleave="leavebox">
      <a v-if="content.urlType == 1" :href="content.url" :title="content.title" target="_blank">
        <img :src="content.fileName" :alt="content.title" />
        <!-- <div class="title" >{{content.title}}</div> -->
      </a>
      <a v-if="content.urlType == 2" :href="`/list/${content.url}`" :title="content.title" target="_blank">
        <img :src="content.fileName" :alt="content.title" />
        <!-- <div class="title" >{{content.title}}</div> -->
      </a>
      <a
        v-if="content.urlType == 3"
        :href="`/detail/${null}/${content.url}/${content.title}`"
        :title="content.title"
        target="_blank"
      >
        <img :src="content.fileName" :alt="content.title" />
        <!-- <div class="title" >{{content.title}}</div> -->
      </a>
      <a v-if="content.urlType == 4" :title="content.title" target="_blank">
        <img :src="content.fileName" :alt="content.title" />
        <!-- <div class="title" >{{content.title}}</div> -->
      </a>
      <b v-if="content && content != '' "  @click="closebox">关闭</b>
    </div>
  </div>
</template>
<script>
import { getAdvert } from '@/api/common'
export default {
  data() {
    return {
      x: 50,
      y: 50,
      xin: true,
      yin: true,
      step: 1,
      delay: 10,
      itl: null,
      showAdbox: true,
      imgUrl: '',
      content: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routeEnterShowAdbox()
    })
  },
  beforeRouteLeave(to, from, next) {
    // eslint-disable-next-line no-unused-vars
    next(vm => {})
    clearInterval(this.itl)
  },
  mounted() {
    this.leavebox()
    this.listData()
  },
  methods: {
    listData() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        advertType: 2
      }
      getAdvert(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length && (_this.content = data[0])
        }
      })
    },
    closebox() {
      this.showAdbox = false
      clearInterval(this.itl)
    },
    leavebox() {
      if (this.showAdbox == false) {
        clearInterval(this.itl)
      } else {
        this.itl = setInterval(this.movebox, this.delay)
      }
    },
    enterbox() {
      clearInterval(this.itl)
    },
    movebox() {
      var L = 0
      var T = 0
      var R = document.documentElement.clientWidth - this.$refs.adbox.offsetWidth //浏览器显示宽度-adbox宽度，不随滚动条变化而变化
      var B = document.documentElement.clientHeight - this.$refs.adbox.offsetHeight //浏览器显示高度-adbox高度，不随滚动条变化而变化
      this.$refs.adbox.style.left = this.x + document.documentElement.scrollLeft + 'px' //adbox初始左+滚动条最左端到浏览器最左端的距离
      this.$refs.adbox.style.top = this.y + document.documentElement.scrollTop + 'px' //adbox初始高+滚动条顶端到浏览器顶端的距离
      this.x = this.x + this.step * (this.xin ? 1 : -1)
      if (this.x < L) {
        this.xin = true
        this.x = L
      }
      if (this.x > R) {
        this.xin = false
        this.x = R
      }
      this.y = this.y + this.step * (this.yin ? 1 : -1)
      if (this.y < T) {
        this.yin = true
        this.y = T
      }
      if (this.y > B) {
        this.yin = false
        this.y = B
      }
    },
    routeEnterShowAdbox() {
      this.itl = setInterval(this.movebox, this.delay)
    }
  }
}
</script>
<style scoped>
#adbox {
  /* width: 300px;
  height: 264px; */
  width: 300px;
  position: absolute;
  z-index: 999;
}
#adbox img {
  width: 100%;
  display: block;
  border: none;
}
#adbox b {
  position: absolute;
  right: 5px;
  top: -20px;
  cursor: pointer;
  color: #333;
  font-size: 16px;
}
#adbox .title {
  background-color: #fff;
  color: #000;
  padding: 0 10px 10px 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
</style>
